import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import NextImage from '@/components/common/NextImage';
import { useAuthContext } from '@/contexts/AuthContext';
import { trackOpenProfileSettingBtnClick } from '@/pages/portal/utils';
import classNames from 'classnames';
import React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import {
  SETTINGS_PAGE_MEMBERSHIP_PATH,
  SETTINGS_PAGE_PROFILE_PATH
} from '@/pages/user/constants';
import Icon from '@/components/npl/Icon';
import {
  getCurrentLanguageLabel,
  getSupportedLocaleOptions,
  setLocale,
  t
} from '@/utility/localization';
import useLocale from '@/hooks/useLocale';
import {
  getRedirectToLink,
  openAppDownloadPage
} from '@/utility/routesHelper';
import { useIntercom } from '@/contexts/IntercomContext/CommonIntercomContext';
import {
  handleLogoutClickFromProductDemo,
  isSessionInSandboxEnv
} from '@/utility/productDemoUtils';
import { trackGAEvent } from '@/utility/analytics';
import { openMemberSupportPage } from '@/utility/freshdeskUtils';

function UserMenuSection({ isCommunityAdmin, communityInfo }) {
  const pathname = usePathname();

  const router = useRouter();
  const { currentLocale } = useLocale();
  const { user, updateUserLocale, isDemoUser, logout } = useAuthContext();
  const { shutdown } = useIntercom();

  const handleProfileBtnClick = () => {
    trackOpenProfileSettingBtnClick(user);
    router.push(
      getRedirectToLink({
        routeTo: SETTINGS_PAGE_PROFILE_PATH,
        redirectTo: pathname,
        activeCommunityId: communityInfo?._id
      })
    );
  };

  const handleMyMembershipsClick = () => {
    trackGAEvent('clicked_my_memberships', { email: user?.email });
    router.push(SETTINGS_PAGE_MEMBERSHIP_PATH);
  };

  function handleLogout() {
    shutdown();

    if (isSessionInSandboxEnv() && isDemoUser) {
      handleLogoutClickFromProductDemo();
      return;
    }

    logout(true, true, '/');
  }

  const profileImage = user?.learner?.profileImage;

  const fullName = `${user?.learner?.firstName ?? ''} ${
    user?.learner?.lastName ?? ''
  }`;

  return (
    <div className="c-UserMenuSection">
      {user && (
        <HeadlessDropdown
          placement="bottom-start"
          key="user-menu"
          renderField={() => {
            return (
              <div
                className={`relative flex cursor-pointer items-center space-x-4 rounded-[48px] px-4 py-2 mix-blend-multiply transition-all hover:bg-npl-neutral-light-alpha-3`}
                data-testid="cmp-nav-user-profile-icon">
                <div className="flex aspect-square w-32 min-w-[32px] items-center">
                  <NextImage
                    mobileImgProps={{
                      src: profileImage,
                      width: 32,
                      height: 32,
                      objectFit: 'cover'
                    }}
                    className="!h-32 w-32 rounded-full"
                  />
                </div>
                <Icon
                  name="menu-01"
                  width={16}
                  height={16}
                  fill="#1B1B1859"
                />
              </div>
            );
          }}
          renderOptions={({ closeDropdown }) => {
            if (isDemoUser) {
              return null;
            }

            return (
              <div className="flex flex-col rounded-12 bg-npl-base-white p-8 xl:min-w-[240px]">
                {/* Profile */}
                <div
                  className="flex flex-row items-center rounded-12 p-8 transition-all hover:bg-npl-neutral-light-alpha-3"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    if (!isDemoUser) handleProfileBtnClick(e);
                  }}>
                  <div
                    className={`relative hidden w-[44px] min-w-[44px] cursor-pointer items-center rounded-28 p-4 transition-all hover:bg-npl-neutral-light-alpha-3 md:flex`}>
                    <NextImage
                      mobileImgProps={{
                        src: profileImage,
                        width: 32,
                        height: 32,
                        objectFit: 'cover'
                      }}
                      className="!h-32 rounded-full"
                    />
                  </div>
                  <div className="ml-12 flex flex-col items-stretch">
                    <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary line-clamp-2	max-w-300">
                      {fullName}
                    </span>
                    <span className="break-all text-label-md text-npl-text-icon-on-light-surface-secondary">
                      {user?.email}
                    </span>
                  </div>
                </div>
                <div className="m-8 border-b-1 border-npl-separator-alpha" />
                <div
                  data-testid="profile-settings-button"
                  className="flex items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                  role="button"
                  tabIndex={0}
                  onClick={handleProfileBtnClick}>
                  <Icon
                    name="user-01"
                    width={16}
                    height={16}
                    fillOpacity={0.65}
                  />
                  <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                    {t('profile-settings')}
                  </span>
                </div>
                <div
                  data-test-id="manage-membership-button"
                  className="flex items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                  role="button"
                  tabIndex={0}
                  onClick={handleMyMembershipsClick}>
                  <Icon
                    name="settings-04"
                    width={16}
                    height={16}
                    fillOpacity={0.65}
                  />
                  <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                    {t('my-memberships')}
                  </span>
                </div>

                <HeadlessDropdown
                  placement="left"
                  renderOptions={({ closeDropdown }) => {
                    return (
                      <ul className="flex min-w-[180px] flex-col rounded-12 bg-npl-base-white p-8 shadow-npl-styles-shadow-02">
                        {getSupportedLocaleOptions().map((option) => (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          <li
                            key={option.value}
                            className="flex cursor-pointer flex-row items-center rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                            value={option.value}
                            onClick={async () => {
                              closeDropdown();
                              await updateUserLocale(option.value);
                              setLocale(option.value);
                            }}>
                            <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                              {option.label}
                            </span>
                            {currentLocale === option.value && (
                              <Icon
                                name="check"
                                width={16}
                                height={16}
                                className="ml-auto"></Icon>
                            )}
                          </li>
                        ))}
                      </ul>
                    );
                  }}
                  renderField={({ isOpen }) => (
                    <div
                      data-testid="language-selector-button"
                      className={classNames(
                        'flex items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3',
                        {
                          'bg-npl-neutral-light-alpha-3': isOpen
                        }
                      )}
                      role="button"
                      tabIndex={0}>
                      <Icon
                        name="globe-02"
                        width={16}
                        height={16}
                        fillOpacity={0.65}
                      />
                      <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                        {`${t('language')}: ${getCurrentLanguageLabel(
                          currentLocale
                        )}`}
                      </span>
                      <Icon
                        name="chevron-right"
                        width={16}
                        height={16}
                        fillOpacity={0.65}
                      />
                    </div>
                  )}
                />
                {!isCommunityAdmin && (
                  <div
                    data-testid="download-app-button"
                    className="flex items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                    role="button"
                    tabIndex={0}
                    onClick={openAppDownloadPage}>
                    <Icon
                      name="hone-01"
                      width={16}
                      height={16}
                      fillOpacity={0.65}
                    />
                    <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                      {t('download-the-nas-io-app')}
                    </span>
                  </div>
                )}
                {!isCommunityAdmin && (
                  <div
                    data-testid="download-app-button"
                    className="flex items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      trackGAEvent('nav-dropdown_contact-support');
                      openMemberSupportPage();
                    }}>
                    <Icon
                      name="message-question-square"
                      width={16}
                      height={16}
                      fillOpacity={0.65}
                    />
                    <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                      {t('contact-support')}
                    </span>
                  </div>
                )}
                <div
                  data-testid="logout-button"
                  className="flex flex-row items-center space-x-12 rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    closeDropdown();
                    handleLogout();
                  }}>
                  <Icon
                    name="log-out"
                    width={16}
                    height={16}
                    fillOpacity={0.65}
                  />
                  <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                    {t('logout')}
                  </span>
                </div>
              </div>
            );
          }}
        />
      )}
    </div>
  );
}

export default UserMenuSection;
