import { t } from '@/utility/localization';

export const PROFILE_TAB_KEY = 'profileTab';
export const SOCIAL_MEDIA_TAB_KEY = 'socialMediaTab';
export const VIDEO_TAB_KEY = 'videoTab';
export const COMMENT_TAB_KEY = 'commentTab';
export const PASSWORD_TAB_KEY = 'passwordTab';

export const SETTINGS_PAGE_PROFILE_ENDPOINT = 'profile';
export const SETTINGS_PAGE_MEMBERSHIP_ENDPOINT = 'membership';

export const SETTINGS_PAGE_PROFILE_PATH = '/user/profile';
export const SETTINGS_PAGE_MEMBERSHIP_PATH = '/user/membership';

export const SETTINGS_PAGE_EMAIL_NOTIFICATIONS_PATH =
  '/user/email-notifications';

export const SETTINGS_PAGE_CALENDAR_PATH = '/user/calendar';
export const SETTINGS_PAGE_CALENDAR_ENDPOINT = 'calendar';

export const SETTINGS_PAGE_EMAIL_NOTIFICATION_ENDPOINT =
  'email-notifications';

export const settingsPagePaths = [
  SETTINGS_PAGE_PROFILE_PATH,
  SETTINGS_PAGE_MEMBERSHIP_PATH
];

export const getSettingPageProfile = () => ({
  name: 'SETTINGS_PAGE_PROFILE',
  label: t('account'),
  labelKey: 'account',
  pathname: SETTINGS_PAGE_PROFILE_PATH,
  endpoint: SETTINGS_PAGE_PROFILE_ENDPOINT
});

export const getSettingPageEmailNotifications = () => ({
  name: 'SETTINGS_PAGE_EMAIL_NOTIFICATIONS',
  label: t('email-notifications'),
  labelKey: 'email-notifications',
  pathname: SETTINGS_PAGE_EMAIL_NOTIFICATIONS_PATH,
  endpoint: SETTINGS_PAGE_EMAIL_NOTIFICATION_ENDPOINT
});

export const getSettingPageCalendar = () => ({
  name: 'SETTINGS_PAGE_CALENDAR',
  label: t('calendar'),
  labelKey: 'calendar',
  pathname: SETTINGS_PAGE_CALENDAR_PATH,
  endpoint: SETTINGS_PAGE_CALENDAR_ENDPOINT
});

export const getSettingPageMembership = () => ({
  name: 'SETTINGS_PAGE_MEMBERSHIP',
  label: t('memberships'),
  labelKey: 'memberships',
  pathname: SETTINGS_PAGE_MEMBERSHIP_PATH,
  endpoint: SETTINGS_PAGE_MEMBERSHIP_ENDPOINT
});

export const pagesList = () => [
  getSettingPageProfile(),
  getSettingPageMembership(),
  getSettingPageEmailNotifications(),
  getSettingPageCalendar()
];

export const STATUS_PENDING = 'PENDING';
